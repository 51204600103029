@import "./_variables.scss";
@import "./mixins.scss";
@import "./animations.scss";

.main-section {
    @include flex-center(center, row);
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;
    margin-bottom: 100px;
    .main-section-text{
        color: white;
        height: 320px;
        .main-section-title {
            p{
                @include gotham-font(90px);
                color: white;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                line-height: 95%;
                width: 700px;
                text-align: left;
                margin-top: 0px;
                margin-bottom: 0px;
                overflow: hidden;
                display: flex;        
            }
            .animation-container{
                height: 85.5px;
                overflow: hidden;
            }
            
            .custom-width{
                width: 320px;
            }

            .evdev-text-animation{
                display: flex;
                justify-content: left;
            }

            .evdev-title {
                display: block;
                @include gotham-font(90px, "medium");
                @include text-gradient;
                animation: evdev-fire 1s forwards;
                animation-delay: 4s;
                transform: scale(0); 
                z-index: 1000;
            }
            .animated-text-nodelay {
                animation: letter-appering 0.7s forwards;
                animation-delay: 2.6s;
                height: 0px;                 
            }
            .animated-text-02sdelay{
                animation: letter-appering 0.7s forwards;
                animation-delay: 2.7s;
                height: 0px; 
            }
            .animated-text-04sdelay{
                animation: letter-appering 0.7s forwards;
                animation-delay: 2.9s;
                height: 0px; 
            }
        }
        p{
            @include codan-font(18px);
            text-align: left;
        }
    }
    .main-section-visual-elem{
        margin-top:-250px;
        height: 400px;
        width: 450px;
        mix-blend-mode: lighten;
        -webkit-mix-blend-mode: lighten;
        .visual-elem-video{
            height: 600px;
            height: 600px;
            mix-blend-mode: lighten;
            -webkit-mix-blend-mode: lighten;


        }
    }
    
    
}
@media screen and (min-width: 768px) {
.discover-projects{
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 70vh;
    width: 600px;
    text-align: center;   
    .projects-button-container{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 500px;
        height: 150px;
        color: white;
        @include codan-font(16px, "medium");
        img{
            transition: 1s;
            &:hover{
                transform: rotate(10deg);
            }
        }
        p{
            margin-top: 60px;
            align-items: center;
            letter-spacing: 0.25em;
            text-transform: uppercase;
        }
    }   
}
}
.projects-button-container:hover .pyramid-image{
    transform: rotate(10deg);
}

.pyramid-image{
    height: 170px !important;
}


@media (max-width: 500px){
    .main-section {
        @include flex-center(center, row);
        height: 100vh;
        width: 100vw;
        overflow-x: hidden;
        position: relative;

        .main-section-text{
            color: white;
            height: 320px;
            z-index: 1000;
            .main-section-title{
                width: 100vw;
                display: flex;
                flex-direction: column;
                justify-content: center;
                p{
                    font-size: 45px !important;
                    display: block;
                }
                .evdev-title {
                    display: block;
                    @include gotham-font(45px, "medium");
                    @include text-gradient;
                    animation: evdev-fire-mobile 1s forwards;
                    animation-delay: 1.1s;
                    transform: scale(0); 
                }
                .animation-container{
                    height: 50px;
                    overflow: hidden;
                    width: 100vw;
                    display: flex;
                    justify-content: center;
                    p{
                        text-align: center !important;
                        width: 100vw;

                    }
                }
                
                .evdev-text-animation{
                    display: flex;
                    justify-content: center;
                }
                .custom-width{
                    width: 160px;
                }
                .animated-text-nodelay {
                    animation: letter-appering-mobile 0.7s forwards;
                    animation-delay: 0.1s;
                    height: 0px;                 
                }
                .animated-text-02sdelay{
                    animation: letter-appering-mobile 0.7s forwards;
                    animation-delay: 0.2s;
                    height: 0px; 
                }
                .animated-text-04sdelay{
                    animation: letter-appering-mobile 0.7s forwards;
                    animation-delay: 0.4s;
                    height: 0px; 
                }
            }
        }
        p{
            text-align: center !important;
        }   
        .main-section-visual-elem{
            position: absolute;
            width: 350px;
            height: 350px;
            top: 150px;
            right: 0px;
            mix-blend-mode: lighten;
            -webkit-mix-blend-mode: lighten;
            .visual-elem-video{
                width: 350px;
                mix-blend-mode: lighten;
                -webkit-mix-blend-mode: lighten;
            }
        }
    }
    .discover-projects{
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        top: 60vh;
        width: 100vw;
        text-align: center;   
        .projects-button-container{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100vw;
            height: 150px;
            color: white;
            @include codan-font(13px, "medium");
            img{
                transition: 1s;
                margin-left: -120px;
                &:hover{
                    transform: rotate(10deg);
                }
            }
            p{
                margin-top: 60px;
                align-items: center;
                letter-spacing: 0.25em;
                text-transform: uppercase;
            }
        }   
    }
}