@import "./_variables.scss";
@import "./mixins.scss";
@import './animations.scss';

@mixin menu-mixin($plank-width: 36.5px) {
    display: block;
    background-color: white;
    height: 1px;
    width: $plank-width;
}
.header-menu-button{
    position: fixed;
    height: 100px;
    width: 100px;
    z-index: 5000;
    .header-menu {
        position: absolute;
        @include menu-mixin();
        margin-left: 58px;
        margin-top: 38px;
        transition: 0.3s;

        &:after{
            content: '';
            @include menu-mixin(15px);
            margin-top:4.5px;
        transition: 0.3s;

        }
    }
    .header-menu-x{
        transition: 0.3s;
        position: absolute;
        @include menu-mixin();
        transform: rotate(45deg);
        margin-left: 58px;
        margin-top: 38px;
        &:after{
            content: '';
            @include menu-mixin();
            // margin-top:4.5px;
            transform: rotate(90deg);
            transition: 0.3s;

        }
    }
}

header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    height: 89px;
    align-items: center;
    justify-content: right;
    backdrop-filter: blur(5px);
    z-index: 1000;


    .logo {
        height: 18.79px;
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        transition: 1s;
    }
}

.header-start-cooperation{
    @include codan-font();
    @include flex-center(center, row);
    color: $white-color;
    width: 250px;
    position: fixed;
    right: 58px;
    top: 23px;
    z-index: 1005 !important;
    &:hover{
        animation: shake 0.7s linear infinite;
    }
    img{
        margin-right: 12px;
    }
    p{
        letter-spacing: 0.15em;
    }
}

.fixed-bottom-controls{
    position: fixed;
    bottom: 0;
    width: 100vw;
    height: 100px;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
    @include codan-font(16px, 'regular');
    .contacts-title {
        margin-left:58px;
        transition: 1s;
        &:hover{
            animation: shake 0.5s linear infinite;
        }
    }
    p{
        line-height: 140%;
        letter-spacing: 0.15em;
    }
    .language-toggle{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 20px;
        width:100px;
        margin-right:58px;
        p{
            padding-left: 10px;
            
            padding-right: 10px;

        }
    }
}

.language-toggle-eng{
    border-right: 1px solid white;
}

.language-toggle-ua {
    opacity: 0.3;
}


@media (max-width: 500px){
    header{
        .header-menu{
            margin-left: 16px;
            display: none !important;
        }
        .header-start-cooperation{
            display: none !important;

            p{
                display: none;
            }
            width: 16px;
            margin-right:16px;

            .language-toggle{
                width:30px;
                p{
                    padding-left: 10px;
                    
                    padding-right: 10px;
        
                }
            }
        }
        
        
    }
    .header-start-cooperation{
        display: none !important;
    }
    .header-menu-button {
        display: none !important;

    }
    
}