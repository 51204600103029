.cursor-elem{
    width: 22px;
    height: 22px;
    top: -11px;
    left: -11px;
    background-color: orange;
    pointer-events: none;
    filter: blur(11.7982px);
    // transition: 0.1s;
    z-index: 10000000 !important;
}


@media (max-width: 500px){
    .cursor-elem{
        display: none;
    }
}