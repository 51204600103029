@import "./_variables.scss";
@import "./mixins.scss";
@import './animations.scss';


.tag-component{
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 8px;
    padding-bottom: 8px;

    
    border-radius: 700px;
    width: max-content;
    // background: linear-gradient(112.53deg, #E9C600 -8.31%, #FF5001 115.37%);
    border: 0.5px solid #e99700;
    height: calc(30px - 16px);
    margin-left: 20px;
    margin-bottom: 12px;
    transition: 0.3s;

    p{
        text-transform: uppercase;
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 300;
        font-size: 11px;
        line-height: 130%;
        width: max-content;
        margin: 0px;
        /* Hot gradient */
        background: linear-gradient(112.53deg, #E9C600 -8.31%, #FF5001 115.37%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;

    }
    &:hover{
        border: 0.5px solid white;
        transition: 0.3s;
        p{
            background: white;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
        }
    }
}