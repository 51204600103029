@font-face {font-family: "Codan Regular";
  src: local('Codan Regular'), local('Codan Regular'),
      url('./fonts/CodanRegularRegular/CodanRegularRegular.ttf') format('truetype'),
      url('./fonts/CodanRegularRegular/CodanRegularRegular.woff2') format('woff2'),
      url('./fonts/CodanRegularRegular/CodanRegularRegular.woff') format('woff');
}
@font-face {font-family: "Codan Medium";
  src: local('Codan Medium'), local('Codan Medium'),
      url('./fonts/CodanMediumRegular/CodanMediumRegular.ttf') format('truetype'),
      url('./fonts/CodanMediumRegular/CodanMediumRegular.woff2') format('woff2'),
      url('./fonts/CodanMediumRegular/CodanMediumRegular.woff') format('woff');
}
@font-face {font-family: "Codan Light";
  src: local('Codan Light'), local('Codan Light'),
      url('./fonts/CodanLightRegular/CodanLightRegular.ttf') format('truetype'),
      url('./fonts/CodanLightRegular/CodanLightRegular.woff2') format('woff2'),
      url('./fonts/CodanLightRegular/CodanLightRegular.woff') format('woff');
}
@font-face {
  font-family: 'Gotham Pro';
  src: local('Gotham Pro Medium'), local('Gotham-Pro-Medium'),
      url('./fonts/GothamPro-Medium.woff2') format('woff2'),
      url('./fonts/GothamPro-Medium.woff') format('woff'),
      url('./fonts/GothamPro-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham Pro';
  src: local('Gotham Pro Regular'), local('Gotham-Pro-Regular'),
      url('./fonts/GothamPro-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham Pro';
  src: local('Gotham Pro Light'), local('Gotham-Pro-Light'),
      url('./fonts/GothamPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}


*{
  cursor: none;
}


body,html{
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
  -webkit-overflow-scrolling:touch;
  touch-action:manipulation;
  scroll-behavior: smooth;
  margin: 0px;
  background: black;
}


.App {
  text-align: center;
  background: black;
  min-height: 2000px;
  isolation: isolate;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 501px) {
  .mobileV {
    display: none !important;
  }
  .desktopV {
    display: block;
  }
}

@media(max-width:500px) {
  .desktopV {
    display: none !important;
  }
  .mobileV {
    display: block;
  }
}