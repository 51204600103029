@import "./_variables.scss";
@import "./mixins.scss";
@import './animations.scss';

.projects-section {
    margin-top: 200px;
    width: 100vw; 
    overflow-y: hidden;
    position: relative;
    
    .projects-title-container{
        width: 1160px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: flex-end;
        z-index: 800;
        position: relative;

        h3{
            width: 850px;
            @include gotham-font(90px);
            line-height: 95%;
            /* or 85px */
            text-align: right;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: #FFFFFF;
            z-index: 1001;


            .evdev-gradient{
                @include text-gradient();
            z-index: 1001;


            }
        }  
        
    }

    .projects-evdev-light{
        margin-top: -150px;
        height: 90px;
        width: 520px;
        z-index: 1;
        // display:block;
    }
    
    .project-element{
        
        width: 1160px;
        height: 1000px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        .description-part{
            width: 460px;
            text-align: left;
            margin-top: -100px;
            .project-name, .project-type{
                border-bottom: 1px solid #464646;
                padding-top: 30px;
                padding-bottom: 30px;

                display: flex;
                flex-direction: column;
                justify-content: left;
                p{
                    color: white;
                    font-family: "Mulish";
                    font-weight: 400;
                    font-size: 11px;
                    line-height: 130%;
                    margin: 0px;
                    text-align: left;
                    width: 460px;

                }
                h4{
                    @include gotham-font(30px, "light");
                    line-height: 130%;
                    color: white;
                    margin: 10px 0px 0px 0px;
                    text-align: left;
                    width: 460px;

                }
            }
            .project-functionality{
                border-bottom: 1px solid #464646;
                padding-top: 30px;
                padding-bottom: 30px;

                display: flex;
                flex-direction: row;
                justify-content: space-between;
                h4{
                    @include gotham-font(30px, "light");
                    line-height: 130%;
                    color: white;
                    margin: 0px;
                    text-align: left;
                }
            }
            .project-functionality-list{
                display: flex;
                flex-direction: column;
                margin-top: 30px;
                height: 0px;
                overflow: hidden;
                transition: 0.2s;
                p{
                    @include codan-font(14px);
                    font-family: 'Codan Light';
                    line-height: 140%;
                    color: #FFFFFF;
                    margin-left: 30px;
                    margin-top: 0px;
                    margin-bottom: 21.8px; 
                }
            }
        }
        .project-image{
            height: 571px;
            width: 722px;
            margin-left: 100px;
            margin-right: -200px;
        }
        .project-image-left{
            height: 571px;
            width: 852px;
            margin-left: -100px;
            margin-right: 160px;
        }
    }
    
}

.open-link-to-project{
    margin-top: -300px;
    display: flex;
    justify-content: center;
    margin-left: -100px;
    text-decoration: none;
    img{
        transition: 1s;
        height: 150px;
        &:hover{
            transform: rotate(10deg);
        }
    }
    p{
        color: white;
        @include codan-font(16px, 'medium');
        letter-spacing: 0.25em;
        text-transform: uppercase;
        margin-top: 85px;
    }
}

.show-more-button{
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 100px;
    padding-top: 20px;

    p{
        color: white;
        @include codan-font(16px, 'medium');
        letter-spacing: 0.25em;
        text-transform: uppercase;
        margin-top: 85px;
        text-align: center;
    }
}


@media  (max-width: 500px){
    // .open-link-to-project{
    //     display: none !important;
    // }
    .projects-section {
        width: 100vw; 
        .projects-title-container{
            width: 95vw;
            margin-left: 2.5vw;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            justify-content: center;
            h3{
                width: 92vw;
                @include gotham-font(45px, "light");
                line-height: 95%;
                /* or 85px */
                text-align: left;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: #FFFFFF;
                .evdev-gradient{
                    @include text-gradient();
                }
            }  
        }
        .project-element{
            width: 100vw;
            margin-top: 100px;
            height: auto;
            overflow-x: hidden;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .description-part{
                width: 95vw;
                margin-left: 2.5vw;
                // margin-right: 2vw;
                text-align: left;
                margin-top: -100px;
                .project-name, .project-type{
                    border-bottom: 1px solid #464646;
                    padding-top: 30px;
                    padding-bottom: 30px;
    
                    display: flex;
                    flex-direction: column;
                    justify-content: left;
                    p {
                        color: white;
                        font-family: "Mulish";
                        font-weight: 400;
                        font-size: 11px;
                        line-height: 130%;
                        margin: 0px;
                        text-align: left;
                        width: 100vw;
    
                    }
                    h4 {
                        @include gotham-font(20px, "light");
                        line-height: 130%;
                        color: white;
                        margin: 10px 0px 0px 0px;
                        text-align: left;
                        width: 95vw;
    
                    }
                }
                .project-functionality{
                    border-bottom: 1px solid #464646;
                    padding-top: 30px;
                    padding-bottom: 30px;
    
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    h4{
                        @include gotham-font(20px);
                        line-height: 130%;
                        color: white;
                        margin: 0px;
                        text-align: left;
                    }
                }
            }
            .project-image{
                height: 316px;
                width: 100vw;
                margin-left: 0px;
                margin-right: 15%;
                margin-bottom: 100px;

            }
            .project-image-left{
                height: 316px;
                width: 100vw;
                margin-left: 15%;
                margin-right: 0px;
                margin-bottom: 100px;
            }
        }
        
    }
    .open-link-to-project{
        margin-top: 0px;
        display: flex;
        justify-content: center;
        margin-left: -100px;
        text-decoration: none;
        img{
            transition: 1s;
            height: 150px;
            &:hover{
                transform: rotate(10deg);
            }
        }
        p{
            color: white;
            @include codan-font(16px, 'medium');
            letter-spacing: 0.25em;
            text-transform: uppercase;
            margin-top: 85px;
        }
    }
}