@import "./variables.scss";
@import "./mixins.scss";
@import './animations.scss';



.evdev-menu {
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: 1005;
    pointer-events: none;
    background: linear-gradient(307.18deg, rgba(36, 36, 36, 0.6) 42.97%, rgba(103, 103, 103, 0.6) 98.82%);
    backdrop-filter: blur(30px);
    opacity: 0; 
    transition: 1s;

    .evdev-menu-anchors{
        margin-left: 0px;
        height: 500px;
        margin-top: calc((100vh - 500px) / 2);
        margin-left: 56px;
        width: fit-content; 
        .evdev-menu-elem{
            width: fit-content; 
            h3{
                text-align: left;
                @include gotham-font(30px, 'medium');
                line-height: 100%;
                color: #717171;
                transition: 0.3s;
                width: fit-content; 
                margin-left: 0px;
                margin-right: 0px;
                margin-bottom: 0px;
                margin-top: 0px;
                padding-top: 30px;
            }
            .line{
                // border-top: 1px solid red;
                transition: 0.3s;
                height: 1px;
                width: 0px;
                background: linear-gradient(112.53deg, #E9C600 -8.31%, #FF5001 115.37%);
            }
        }
        .evdev-menu-elem:hover{
            h3{
                background: linear-gradient(112.53deg, #E9C600 -8.31%, #FF5001 115.37%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
                opacity: 1;
                max-width: 380px;
                transition: 0.3s;
                @include gotham-font(40px, 'medium');
            }
        }
        .evdev-menu-elem:hover .line{
            transition: 0.3s;
            width: 100%;
        }
    }
}

.menu-opened{
    backdrop-filter: blur(30px) !important;
    opacity: 1 !important; 
    width: 100vw;
    transition: 1s;
    pointer-events: auto;

}