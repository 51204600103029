@import "./_variables.scss";
@import "./mixins.scss";
@import './animations.scss';

.what-other-talk-section{
    width: 1160px;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    .title-text{
        @include title-text();
        width: 700px;
    }
    .title-text-gradient{
        @include title-text();
        // @include text-gradient();
        width: 900px;
    }
    .sticky-content{
        position: sticky;
        position: -webkit-sticky;
        top: 120px;
    }
    .reviews-items{
        margin-top: 500px;
        z-index: 100;
        width: 780px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        float: right;
        margin-right: 0px;
        margin-left: auto;
        backdrop-filter: blur(10px);
        gap: 40px;
        .review-item{
            width: 350px;
            height: 300px;
            text-align: left;
            img {
                width: 350px;
            }
            .review-spec{
                font-family: 'Mulish';
                font-size: 11px;
                @include text-gradient();
                text-transform: uppercase;
                margin-left: 10px;
            }
            .review-name{
                margin-top:0px;
                margin-left: 10px;

                margin-bottom: 25px;
                color: white;
                @include gotham-font(30px);
            }
            hr{
                // border: none;
                border-bottom: 1px solid #464646;
                border-left: none;
                border-top: none;
                border-right: none;

                color:#464646;
                // background-color: #464646;
            }
            .review-social-media{
                display: flex;
                // align-items: center;
                justify-content: space-between;
                // margin-top: 20px;
                a{
                margin-left: 10px;
                    text-decoration: none;
                    color: white;
                    @include codan-font(14px, "regular");
                }
                .review-social-media-links{
                    display: flex;
                    align-items: center;

                    img{
                        height: 24px;
                        width: 24px;
                        margin-left: 12px;
                    }
                }
            }
        }
        .review-item:nth-child(2n){
            margin-top: 220px;
        }
    }
}

.iframe-styles{
    border: none !important;
}

.background-element{
    position: absolute;
    left: 0px;
    transition: 0.1s;

}

.background-element-2{
    transition: 0.1s;
    position: absolute;
    left: 200px;
    height: 170px;
}
.background-element-3{
    transition: 0.1s;
    position: absolute;
    left: 800px;
    height: 170px;
}

@media (max-width: 500px) {
    .what-other-talk-section{
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-left: auto;
        margin-right: auto;
        .title-text{
            @include title-text(45px);
            width: 95vw;
            margin-left:5vw;
        }
        .title-text-gradient{
            @include title-text();
            // @include text-gradient();
            width: 80vw;
            margin-left: 5vw;
        }
        .sticky-content{
            position: sticky;
            position: -webkit-sticky;
            top: 120px;
        }
        .reviews-items{
            margin-top: 500px;
            z-index: 100;
            width: 95vw;
            margin-left: 2.5vw;
            display: flex;
            justify-content: flex-start;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-start;
            // float: right;
            margin-right: 0px;
            margin-left: auto;
            backdrop-filter: blur(5px);
            // gap: 40px;
            .review-item{
                width: 40vw;
                height: 300px;
                text-align: left;
                img {
                    width: 40vw;
                    height: 216px;
                    object-fit: cover;
                }
                .review-spec{
                    font-family: 'Mulish';
                    font-size: 11px;
                    @include text-gradient();
                    text-transform: uppercase;
                    margin-left: 10px;
                }
                .review-name{
                    margin-top:0px;
                    margin-left: 10px;
    
                    margin-bottom: 25px;
                    color: white;
                    @include gotham-font(16px);
                }
                hr{
                    // border: none;
                    border-bottom: 1px solid #464646;
                    border-left: none;
                    border-top: none;
                    border-right: none;
    
                    color:#464646;
                    // background-color: #464646;
                }
                .review-social-media{

                    a{
                        margin-left: 10px;
                        text-decoration: none;
                        text-align: center;
                        color: white;
                        @include codan-font(14px, "regular");
                    }
                }
            }
            .review-item:nth-child(2n){
                margin-top: 100px;
            }
        }
    }
}


