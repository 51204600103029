@import "./_variables.scss";
@import "./mixins.scss";
@import './animations.scss';

.pick-industry-section{
    @include flex-center();
    width: 100vw;
}

.pick-industry-container{
    width: 1160px;
    height: 390px;     
    background: linear-gradient(320.54deg, rgba(36, 36, 36, 0.1) 2.41%, rgba(137, 137, 137, 0.1) 99.07%);
    backdrop-filter: blur(30px);
    display: flex;
    justify-content: space-around;
    .left-part{
        width: 250px;
        height: 150px;
        text-align: left;
        .counter-block{
            display: flex;
            margin-bottom: 43px;
            margin-top: 84px;
        }
        .current-step {
            color: white;
            // margin-top: 10px;
            margin: 0px;
            @include codan-font(18px);
            line-height: 160%;
            width: 21px;
            height: 29px;
        }
        .all-steps {
            color: #6D6D6D;
            @include codan-font(11px);
            line-height: 160%;
            width: 21px;
            margin: 0px;
            margin-left: 5px;
            height: 18px;

        }
        h3{
            @include gotham-font(45px);
            font-weight: 300;
            margin-top: 0px;
            margin-bottom: 0px;
            line-height: 110%;
            color: white;
        }
    }
    .right-part{
        width: 560px;
        height: 156px;  
        margin-top: 150px;
        display: flex;
        justify-content: flex-start;
        align-content: flex-start;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .right-part-noflex{
        width: 560px;
        height: 156px;  
        margin-top: 150px;
    }
}

.recommended-project{
    width: 271px;
    height: 154px;
    background-color: #202b41;
    border: 1px solid white;
    display: flex !important;
    align-items: center;
    justify-content: center;
    img{
        max-height: 40px !important;
    }


}


.pickind-evdev-light{
    position: absolute;
    z-index: 0;
}

.background-element-industry{
    position: absolute;
    z-index: 0;
    margin-left: -800px;
    margin-top: 400px;
}
