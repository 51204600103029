@import "./_variables.scss";
@import "./mixins.scss";
@import './animations.scss';


.client-section{
    height: 1400px;
    margin-top: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    .clients-section-title{
        margin-top: 200px;
        @include gotham-font(90px);
        text-align: center;
        width: 100vw;
        line-height: 95%;
        /* or 85px */
        letter-spacing: 0.1em;
        text-transform: uppercase;

        /* White */
        color: #FFFFFF;
        z-index: 1000;
    }
    .clients-evdev-light{
        // position: absolute;
        margin-top: -130px;
        margin-left: 400px;
        height: 90px;
        width: 520px;
    }
    // .logos-section{
    //     display: flex;
    //     transform: rotate(-15deg);
    //     width: 100vw;


    //     .logos-section-elem{
    //         width: 100px;
    //         img {
    //             transform: rotate(0deg);
    //             height: 40px;
    //             margin-left: 50px;
    //             margin-right: 50px;

    //         }
    //     }

    // }

    .logos-section{
        display: flex;
        transform: rotate(-15deg);
        width: 100vw;
        margin-top: 200px;
        transition: 0.1s;
        .logos-section-elem{
            img{
                height: 40px;
                margin-left:50px;
                margin-right:50px;
            }
        }
    }

    .logos-section:nth-child(2){
        margin-top: 100px;
    }
    .logos-section:nth-child(4){
        margin-top: 50px;
    }
    .logos-section:nth-child(5){
        margin-top: 150px;
    }
    .top50{
        margin-top:50px;
    }
    .top100{
        margin-top:100px;
    }
}




@media (max-width: 500px){
    .client-section{
        margin-top: 0px;
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden !important;
        position: relative;
        .clients-evdev-light{
            position: absolute;
            margin-top: 540px;
            margin-left: 0px !important;
            height: 60px;
            width: 300px !important;
        }

        .clients-section-title{
            margin-top: 100px;
            @include gotham-font(45px);
            text-align: center;
            width: 95vw;
            line-height: 95%;
            /* or 85px */
            letter-spacing: 0.1em;
            text-transform: uppercase;
    
            /* White */
            color: #FFFFFF;
        }
        .logos-section{
            // display: flex;
            transform: rotate(-15deg);
            width: 100vw;
            
    
            .logos-section-elem{
                img {
                    transform: rotate(0deg);
                    margin-left: 25px;
                    margin-right: 25px;
                    
                }
            }
    
        }
        .logos-section:nth-child(2n){
            margin-top: 100px;
        }
        .logos-section:nth-child(4n){
            margin-top: 50px;
        }
        .logos-section:nth-child(5n){
            margin-top: 100px;
        }
        .top50{
            margin-top:50px;
        }
        .top-mobile{
            margin-top: 100px;
        }
    }
    
}