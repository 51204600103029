
@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');

@mixin codan-font($font-size: 16px, $type: "regular"){
  font-size: $font-size;
  font-style: normal;
  @if $type == "regular" {
    font-family: "Codan Regular";
  }
  @if $type == "light" {
    font-family: "Codan Light";
  }
  @if $type == "medium" {
    font-family: "Codan Medium";
  }

  }

@mixin gotham-font($font-size: 16px, $type: "regular"){
  font-size: $font-size;
  font-family: "Gotham Pro";
  @if $type == "light" {
    font-weight: 300;
  }
  @if $type == "regular" {
    font-weight: 400;
  }
  @if $type == "medium" {
    font-weight: 500;
  }
} 

@mixin flex-center($position: center, $flex-direction: column){
  display: flex;
  justify-content: $position;
  flex-direction: $flex-direction;
  align-items: center;
}

@mixin text-gradient(){
  background: linear-gradient(112.53deg, #E9C600 -8.31%, #FF5001 115.37%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}



@mixin title-text($fontsize: 90px){
  @include gotham-font($fontsize);
  color: white;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  line-height: 95%;
  width: 700px;
  text-align: left;
  margin-top: 0px;
  margin-bottom: 0px;
  overflow: hidden;
  display: flex;
}