@import "./_variables.scss";
@import "./mixins.scss";
@import './animations.scss';

.customer-get-section{
    margin-top: 200px;
    width: 100vw;
    overflow-y: hidden;
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;

    h3{
        @include gotham-font(70px);
        line-height: 95%;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        width: 600px;
        text-align: left;
        color: white;
        margin: 20px 0px;
        z-index: 1000;

        .gradient-txt{
            @include text-gradient();
            z-index: 1000;
        }
    }
    .service-element{
        position: relative;
        .customer-evdev-light{
            position: absolute;
            margin-top: -200px;
            left: 0px;
            height: 90px;
            width: 520px;
            z-index: -1;
        }
    }


    .services-list {
        display: flex;
        flex-direction: column;
        width: 560px;
        .service-element{
            @include codan-font(20px);
            color: white;
            padding-top: 24px;
            padding-bottom: 24px;
            margin: 0px;
            border-bottom: 1px solid #464646;
            text-align: left;
            p{
                margin: 0px;
                line-height: 160%;
            }
            .subtitle{
                @include codan-font(14px);
                margin-top: 0px;
            }
        }
    }

}

@media (max-width: 500px){
    .customer-get-section{
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 95vw;
        margin-left: 2.5vw;
        margin-right: 2.5vw;
        padding-bottom: 200px;
        overflow: hidden;
        h3{
            @include gotham-font(45px);
            line-height: 95%;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            width: 95vw;
            text-align: left;
            color: white;
            margin: 20px 0px;
            .gradient-txt{
                @include text-gradient();
            }
        }
        .services-list {
            display: flex;
            flex-direction: column;
            width: 95vw;
            .service-element{
                @include codan-font(20px);
                color: white;
                padding-top: 24px;
                padding-bottom: 24px;
                margin: 0px;
                border-bottom: 1px solid #464646;
                text-align: left;
                p{
                    margin: 0px;
                    line-height: 160%;
                }
                .subtitle{
                    @include codan-font(14px);
                    margin-top: 0px;
                }
            }
        }
    
    }
}