@import "./_variables.scss";
@import "./mixins.scss";
@import './animations.scss';


.project-development-section {
    margin-top: 200px;
    width: 100vw;
    overflow-x: hidden;
    position: relative;

    .project-development-title{
        h4{
            width: 1160px;
            @include gotham-font(90px);
            line-height: 95%;
            /* or 85px */
            text-align: left;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: #FFFFFF;
        }
        width: 1162px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        .evdev-gradient{
            @include text-gradient();
            margin-right: 40px;
        }
        .project-development-evdev-light{
            position: absolute;
            margin-top: -350px;
            left: 0px;
            height: 90px;
            width: 520px;
            z-index: -1;
        }

    }
    .project-development-stage{
        display: flex;
        justify-content: center;
        align-items: center;
        .project-development-image{
            .visual-elem-video{
                height: 600px;
                width: 600px;
                margin-right: 100px;
            }
        }
        .project-stage-title{
            width: 500px;
            text-align: left;
            margin-left: -100px;
            z-index: 1100;

            h3{
                @include text-gradient();
                @include gotham-font(70px);
                width: 500px;
                text-align: left;
                margin: 31px 0px;
                line-height: 95%;
                letter-spacing: 0.1em;
                
                z-index: 1100;

            }
            h4{
                @include gotham-font(50px, "light");
                line-height: 110%;
                margin: 0px;
                color: #FFFFFF;

            }
            p{
                @include codan-font(18px, "light");
                color: white;
                line-height: 160%;
                margin: 0px;
                width: 400px;

            }
        }
    }
    .project-development-stage-left{
        display: flex;
        justify-content: left;
        align-items: center;
        height: 70vh;
        width: 1160px;
        margin-left: auto;
        margin-right: auto;
        .project-stage-title-left{
            text-align: left;
            // margin-left: 100px;
            h3{
                @include text-gradient();
                @include gotham-font(70px);
                width: 100%;
                text-align: left;
                margin: 31px 0px;
                line-height: 95%;
                letter-spacing: 0.1em;
                z-index: 1100;


            }
            h4{
                @include gotham-font(50px, "light");
                line-height: 110%;
                margin: 0px;
                z-index: 1100;

                color: #FFFFFF;

            }   
            p{
                @include codan-font(18px, "light");
                color: white;
                line-height: 160%;
                margin: 0px;
                width: 400px;
                z-index: 1100;

            }
        }
        .project-development-image-left{
            position: absolute;
            right: 0;

            .visual-elem-video-left{
                width: 600px;
                height: 600px;
                right: 0;
                margin-top: -300px;
                position: absolute;
            }
            @media(min-width: 1600px){
                img {
                    width: 800px;
                    height: 800px;
                    right: 20%;
                    margin-top: -400px;
                    position: absolute;
                }
            }
        }
    }
}


@media (max-width: 500px){
    .project-development-section {
        width: 100vw;
        overflow-x: hidden;
        .project-development-title{
            h4{
                width: 95vw;
                @include gotham-font(45px);
                line-height: 95%;
                /* or 85px */
                text-align: left;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: #FFFFFF;
            }
            width: 95vw;
            margin-left: auto;
            margin-right: auto;
            .evdev-gradient{
                @include text-gradient();
            }
    
        }
        .project-development-stage{
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            // height: 80vh;
            width: 95vw;
            .project-development-image{
                .visual-elem-video{
                    height: 350px;
                    width: 350px;
                    margin-right: 0px;
                }
            }
            .project-stage-title{
                width: 260px;
                text-align: left;
                margin-left: 100px;
                z-index: 1000;
                
                h3{
                    @include text-gradient();
                    @include gotham-font(45px);
                    width: 260px;

                    text-align: left;
                    margin: 31px 0px;
                    line-height: 95%;
                    letter-spacing: 0.1em;
                    z-index: 1100;

    
                }
                h4{
                    @include gotham-font(30px, "light");
                    line-height: 110%;
                    margin: 0px;
                    color: #FFFFFF;
                    z-index: 1100;

    
                }
                p{
                    @include codan-font(16px, "light");
                    color: white;
                    line-height: 160%;
                    margin: 0px;
                    width: 260px;
                    z-index: 1100;

    
                }
            }
        }
        .project-development-stage-left{
            display: flex;
            justify-content: left;
            align-items: center;
            height: 40vh;
            margin-top: 30vh;
            width: 95vw;
            margin-left: auto;
            margin-right: auto;
            .project-stage-title-left{
                text-align: left;
                margin-left: 10px;
                z-index: 1000;
                
                h3{
                    @include text-gradient();
                    @include gotham-font(45px);
                    width: 70vw;
                    text-align: left;
                    margin: 31px 0px;
                    line-height: 95%;
                    letter-spacing: 0.1em;
                    word-wrap: break-word;
                    overflow: hidden; 
                    z-index: 1100;

    
                }
                h4{
                    @include gotham-font(30px, "light");
                    line-height: 110%;
                    margin: 0px;
                    color: #FFFFFF;
                    z-index: 1100;

    
                }   
                p{
                    @include codan-font(16px, "light");
                    color: white;
                    line-height: 160%;
                    margin: 0px;
                    width: 400px;
                    z-index: 1100;

                }
            }
            .project-development-image-left{
                position: absolute;
                right: 0;
    
                .visual-elem-video-left{
                    height: 350px;
                    width: 350px;
                    right: 0;
                    margin-top: -400px;
                    position: absolute;
                }
                @media(min-width: 1600px){
                    img {
                        width: 800px;
                        height: 800px;
                        right: 20%;
                        margin-top: -400px;
                        position: absolute;
                    }
                }
            }
        }
    }
}